//Lib file to post/get/put etc....
//URL endpoint is hadedcoded


const api_server = process.env.REACT_APP_API_URL ?? /*'https://api-dev.orderonbot.com'*/ 'http://localhost:3001';
const api_order_server = process.env.REACT_APP_ORDER_API_URL ?? /*'https://orderapi-dev.orderonbot.com'*/ 'http://localhost:9090';


 function getAPIkey () {

  
  let authHeader = ''

  

    const apiKey = localStorage.getItem("patronKey");

    authHeader = apiKey;

    console.log(authHeader);
  
      
  return authHeader;

}

export async function PostOrderData(endpoint = '', data = {}, headers={}){
  if(!endpoint )
  {
    console.log('Endpoint missing, nowhere to post');
    return false;
  }
  if(!headers)
  {
    console.log('Headers missing, import fields missing, read the documentation carefully');
    return false;
  }
  if(!data)
  {
      
      console.log('No data, nothing to post');
      return false;
  }
  console.log('Post data to: ' +api_order_server + endpoint); 
  if(typeof(data)==="string")
  {
    headers.content_type="application/json";
    headers=JSON.stringify(headers);
    headers=headers.replace('content_type','Content-Type');
    headers=JSON.parse(headers);
    headers["apikey"] = getAPIkey();
    headers["source"] = "widget";
  }

  const response = await fetch(api_order_server+endpoint, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      "headers": headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects    
    //response.then(res => res.json());
    //console.log(res);
    //return response;
};

export async function PostData(endpoint = '', data = {}, headers={}){
    if(!endpoint )
    {
      console.log('Endpoint missing, nowhere to post');
      return false;
    }
    if(!headers)
    {
      console.log('Headers missing, import fields missing, read the documentation carefully');
      return false;      
    }
    if(!data)
    {
        
        console.log('No data, nothing to post');
        return false;
    }
    console.log('Post data to: ' +api_server + endpoint); 
    if(typeof(data)==="string")
    {
      headers.content_type="application/json";
      headers=JSON.stringify(headers);
      headers=headers.replace('content_type','Content-Type');
      headers=JSON.parse(headers);
      headers["apikey"] = getAPIkey();
      headers["source"] = "widget";
    }

    const response = await fetch(api_server+endpoint, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        "headers": headers,
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: data // body data type must match "Content-Type" header
      });
      return response.json(); // parses JSON response into native JavaScript objects    
      //response.then(res => res.json());
      //console.log(res);
      //return response;
};

export async function PutData(endpoint = '', data = {}, headers={}){
  if(!endpoint)
  {
      console.log('Endpoint missing, nowhere to post');
      return false;
  }
  if(!data)
  {
      console.log('No data, nothing to post');
      return false;
  }
  console.log('Putting at ' +api_server + endpoint);


  if(typeof(data)==="string")
  {
    headers.content_type="application/json";
    headers=JSON.stringify(headers);
    headers=headers.replace('content_type','Content-Type');
    headers=JSON.parse(headers);
    headers["apikey"] = getAPIkey();
    headers["source"] = "widget";
  }

  const response = await fetch(api_server+endpoint, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      "headers": headers,
      //headers: {business_id:1, store_id:2},
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body:data
    });
    return response.json(); // parses JSON response into native JavaScript objects    
    //response.then(res => res.json());
    //console.log(res);
    //return response;
};

export async function DeleteData(endpoint = '', headers={}){
  if(!endpoint)
  {
      console.log('Endpoint missing, nowhere to post');
      return false;
  }
  if(!headers)
  {
    console.log('Headers missing, read documention');
    return false;   
  }

  headers["apikey"] = getAPIkey();
  headers["source"] = "widget";
  
  console.log('Deleting at ' +api_server + endpoint);
  
  const response = await fetch(api_server+endpoint, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //--body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects    
    //response.then(res => res.json());
    //console.log(res);
    //return response;
};

export async function PatchData(endpoint = '', data = {}, headers={}){
  if(!endpoint)
  {
      console.log('Endpoint missing, nowhere to patch');
      return false;
  }
  if(!headers)
  {
    console.log('Headers missing, read documention');
    return false;   
  }  
  if(!data)
  {
      
      console.log('No data, nothing to patch');
      return false;
  }

  headers["apikey"] = getAPIkey();
  headers["source"] = "widget";
  //return false;
  const response = await fetch(api_server+endpoint, {
      method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects    

};

export async function GetData(endpoint = '',  headers={}){
  if(!endpoint)
  {
      console.log('Endpoint missing, nothing to get');
      return false;
  }
  if(!headers)
  {
    console.log('Headers missing, read documention');
    return false;   
  }  

  headers["apikey"] = getAPIkey();
  headers["source"] = "widget";

  console.log('Get data to: ' + api_server + endpoint); 
  //console.log('header');
  //console.log(headers);
  //return false;
    const response = await fetch(api_server+endpoint, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify('') // body data type must match "Content-Type" header
    });
    return response.json();
    
};

//GetImage is indetical to get data but just passes on Binary data without JSON pasrsing
export async function GetImage(endpoint = '',  headers={}){
  if(!endpoint)
  {
      console.log('Endpoint missing, nothing to get');
      return false;
  }
  if(!headers)
  {
    console.log('Headers missing, read documention');
    return false;   
  }  

  


  console.log('Get data to: ' + api_server + endpoint); 
  //console.log('header');
  //console.log(headers);
  //return false;
    const response = await fetch(api_server+endpoint, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify('') // body data type must match "Content-Type" header
    });
    return response.blob();
    
};

export async function GetOrderData(endpoint = '',  headers={}){
  //sendEvent("AA_Fetching", "GetData", "");
  if(!endpoint)
  {
      console.log('Endpoint missing, nothing to get');
      return false;
  }
  if(!headers)
  {
    console.log('Headers missing, read documention');
    return false;   
  } 

  const patronInfo = JSON.parse(localStorage.getItem("patronInfo") || "{}");



  headers["apikey"] = getAPIkey();
  headers["source"] = "widget";
  headers["patron_id"] = patronInfo.patron_d_id;

  console.log('Get data to: ' + api_order_server + endpoint); 
  //const authHeader = await getAccessToken(endpoint, 'read');
  //headers["Authorization"] = authHeader;
  const response = await fetch(api_order_server+endpoint, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //body: JSON.stringify('') // body data type must match "Content-Type" header
  });
  //sendEvent("AA_Sucessful", "GetData", "");
  return response.json();
    
};

export async function PutOrderData(endpoint = '', data = {}, headers={}){
  // sendEvent("AA_Saving", "PutData", "");
  if(!endpoint)
  {
      console.log('Endpoint missing, nowhere to post');
      return false;
  }
  if(!data)
  {
      console.log('No data, nothing to post');
      return false;
  }

  headers["apikey"] = getAPIkey();
  headers["source"] = "widget";

  console.log('Putting at ' +api_order_server + endpoint);


  if(typeof(data)==="string")
  {
    headers.content_type="application/json";
    headers=JSON.stringify(headers);
    headers=headers.replace('content_type','Content-Type');
    headers=JSON.parse(headers);
    //data=data.replace( /[\r\n]+/gm, "\\n" );
    //console.log('O:',data);
    data=JSON.stringify(data);
    //console.log('S:',data);
    data=JSON.parse(data);
    //console.log('P:',data);    
    //data=data.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t");
    data=data.replace(/\n/g, "\\n").replace(/\r/g, "\\r").replace(/\t/g, "\\t");
    //console.log('R:',data);
   }

    // const authHeader = await getAccessToken(endpoint, 'read');

    // headers["Authorization"] = authHeader;

  const response = await fetch(api_order_server+endpoint, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      "headers": headers,
      //headers: {business_id:1, store_id:2},
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: data
    });
    // sendEvent("AA_Sucessful", "PutOrderData", "");
    return response.json(); // parses JSON response into native JavaScript objects    
    //response.then(res => res.json());
    //console.log(res);
    //return response;
};

export async function DeleteOrderData(endpoint = '', headers={}){
  if(!endpoint)
  {
      console.log('Endpoint missing, nowhere to post');
      return false;
  }
  if(!headers)
  {
    console.log('Headers missing, read documention');
    return false;   
  }

  headers["apikey"] = getAPIkey();
  headers["source"] = "widget";

  console.log('Deleting at ' +api_order_server + endpoint);
  //console.log('Data:');
  //console.log(data);
  const response = await fetch(api_order_server+endpoint, {
      method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //--body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects    
    //response.then(res => res.json());
    //console.log(res);
    //return response;
};