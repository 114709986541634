import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

const InvalidURL = () => {
  // const url= "https://oob.uk/T-0143QWZR"


  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '60vh', 
    },
    card: {
      minWidth: 400, 
    },
  };
  return (
        <div>
          <div style={styles.container}>
            <Card style={styles.card}>
              <div style={{ color: 'red', textAlign: 'center', fontSize: "30px",padding: "40px" }}>Invalid URL</div>
            </Card>
          </div>
        </div>
  );
};

export default InvalidURL;
