import { Routes, Route } from 'react-router-dom';
import TinyURL from './TinyURL';

import InvalidURL from './invalidURL';

function App() {
 return (
   <Routes>

     <Route path="/:Id" element={<TinyURL />} />

     <Route path="/invalid" element={<InvalidURL />} />
   </Routes>
 );
}

export default App; 