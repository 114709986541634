import React, { useEffect, useState } from 'react';
import { GetData } from './lib/ApiConnect';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const TinyURL = () => {
  const [errorMessage, setErrorMessage] = useState('');
  // const url= "https://oob.uk/T-0143QWZR"

  const { Id } = useParams();

  console.log(Id);

  useEffect(() => {
    async function getData() {

      try {
        // const new_url = window.location.href; 
        // const fullUrl = url.replace(window.location.origin, 'https://oob.uk'); // Replace the origin with the full URL format
        const headers = {
          'url': Id,
        };

        const response = await GetData('/redirect', headers);
        console.log(response,"response")
        if (response.status) {
          window.location.href = response.originalUrl; 
        } else {
          setErrorMessage('Invalid URL'); 
        }
      } catch (error) {
        setErrorMessage('Could not route to destination');
      }
    }

    getData();
  }, [Id]);
  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '60vh', 
    },
    card: {
      minWidth: 400, 
    },
  };
  return (
        <div>
          <div style={styles.container}>
            <Card style={styles.card}>
              <div style={{ color: 'red', textAlign: 'center', fontSize: "30px",padding: "40px" }}>{errorMessage}</div>
            </Card>
          </div>
        </div>
  );
};

export default TinyURL;
